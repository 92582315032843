import React, { useState, useEffect } from 'react';
import './App.css';
import config from './config';

// Import components (to be created)
import BotConfiguration from './components/BotConfiguration';
import MarketOverview from './components/MarketOverview';
import NeuralNetworkVisualization from './components/NeuralNetworkVisualization';
import PerformanceMetrics from './components/PerformanceMetrics';
import BacktestingResults from './components/BacktestingResults';
import ErrorLogs from './components/ErrorLogs';
import LoginForm from './components/LoginForm';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [botConfig, setBotConfig] = useState({
    alphaVantageKey: '',
    newsdataKey: '',
    tradingMode: 'backtesting'
  });

  const [marketData, setMarketData] = useState({
    totalTrades: 0,
    successfulTrades: 0,
    winRate: 0,
    profitLoss: 0
  });

  const [neuralNetworkData, setNeuralNetworkData] = useState(null);
  const [performanceMetrics, setPerformanceMetrics] = useState(null);
  const [backtestingResults, setBacktestingResults] = useState(null);
  const [errorLogs, setErrorLogs] = useState([]);

  const handleLogin = (username, password) => {
    console.log('handleLogin function called');
    console.log('Login attempt - Username:', username, 'Password:', password);
    console.log('Current isAuthenticated state:', isAuthenticated);

    // Updated credentials check
    if (username === 'singh' && password === '2510') {
      console.log('Credentials match');
      setIsAuthenticated(true);
      console.log('isAuthenticated set to true');
    } else {
      console.log('Credentials do not match');
      alert('Invalid credentials');
    }

    console.log('handleLogin function completed');
  };

  useEffect(() => {
    console.log('isAuthenticated state changed:', isAuthenticated);
    // Fetch initial data from the backend
    // This will be implemented later
  }, [isAuthenticated]);

  console.log('Rendering App component');
  console.log('Current isAuthenticated state:', isAuthenticated);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Neural Nets Robot Trading Dashboard</h1>
      </header>
      {isAuthenticated ? (
        <main>
          <BotConfiguration config={botConfig} setConfig={setBotConfig} />
          <MarketOverview data={marketData} />
          <NeuralNetworkVisualization data={neuralNetworkData} />
          <PerformanceMetrics data={performanceMetrics} />
          <BacktestingResults data={backtestingResults} />
          <ErrorLogs logs={errorLogs} />
        </main>
      ) : (
        <LoginForm onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
