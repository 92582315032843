import React from 'react';

const PerformanceMetrics = ({ data }) => {
  return (
    <div className="performance-metrics">
      <h2>Performance Metrics</h2>
      {data ? (
        <div>
          <p>Total Return: {data.totalReturn ? `${data.totalReturn.toFixed(2)}%` : 'N/A'}</p>
          <p>Sharpe Ratio: {data.sharpeRatio ? data.sharpeRatio.toFixed(2) : 'N/A'}</p>
          <p>Max Drawdown: {data.maxDrawdown ? `${data.maxDrawdown.toFixed(2)}%` : 'N/A'}</p>
          {/* Add more performance metrics as needed */}
        </div>
      ) : (
        <p>No performance data available</p>
      )}
    </div>
  );
};

export default PerformanceMetrics;
