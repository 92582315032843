import React from 'react';

const ErrorLogs = ({ logs }) => {
  return (
    <div className="error-logs">
      <h2>Error Logs</h2>
      {logs && logs.length > 0 ? (
        <ul>
          {logs.map((log, index) => (
            <li key={index}>{log}</li>
          ))}
        </ul>
      ) : (
        <p>No error logs available</p>
      )}
    </div>
  );
};

export default ErrorLogs;
