import React from 'react';

const MarketOverview = ({ data }) => {
  return (
    <div className="market-overview">
      <h2>Market Overview</h2>
      <div>
        <p>Total Trades: {data.totalTrades}</p>
        <p>Successful Trades: {data.successfulTrades}</p>
        <p>Win Rate: {data.winRate}%</p>
        <p>Profit/Loss: ${data.profitLoss}</p>
      </div>
    </div>
  );
};

export default MarketOverview;
