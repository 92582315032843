import React from 'react';

const BotConfiguration = ({ config, setConfig }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setConfig(prevConfig => ({
      ...prevConfig,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the configuration to the backend
    console.log('Configuration submitted:', config);
    // TODO: Implement API call to save configuration
  };

  return (
    <div className="bot-configuration">
      <h2>Bot Configuration</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="alphaVantageKey">Alpha Vantage API Key:</label>
          <input
            type="text"
            id="alphaVantageKey"
            name="alphaVantageKey"
            value={config.alphaVantageKey}
            onChange={handleInputChange}
            placeholder="Enter Alpha Vantage API Key"
          />
        </div>
        <div>
          <label htmlFor="newsdataKey">Newsdata.io API Key:</label>
          <input
            type="text"
            id="newsdataKey"
            name="newsdataKey"
            value={config.newsdataKey}
            onChange={handleInputChange}
            placeholder="Enter Newsdata.io API Key"
          />
        </div>
        <div>
          <label htmlFor="tradingMode">Trading Mode:</label>
          <select
            id="tradingMode"
            name="tradingMode"
            value={config.tradingMode}
            onChange={handleInputChange}
          >
            <option value="backtesting">Backtesting</option>
            <option value="paperTrading">Paper Trading</option>
            <option value="realTrading">Real Trading</option>
          </select>
        </div>
        <button type="submit">Save Configuration</button>
      </form>
    </div>
  );
};

export default BotConfiguration;
