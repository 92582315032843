import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const NeuralNetworkVisualization = ({ data }) => {
  const chartData = {
    labels: data?.layers ? data.layers.map((_, index) => `Layer ${index + 1}`) : [],
    datasets: [{
      label: 'Neurons per Layer',
      data: data?.layers ? data.layers.map(layer => layer.neurons) : [],
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1
    }]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Neural Network Architecture'
      }
    }
  };

  return (
    <div className="neural-network-visualization">
      <h2>Neural Network Visualization</h2>
      {data ? (
        <div>
          <p>Input Size: {data.inputSize}</p>
          <p>Layers: {data.layers ? data.layers.length : 'N/A'}</p>
          <Bar data={chartData} options={options} />
        </div>
      ) : (
        <p>No neural network data available</p>
      )}
    </div>
  );
};

export default NeuralNetworkVisualization;
