import React from 'react';

const BacktestingResults = ({ data }) => {
  return (
    <div className="backtesting-results">
      <h2>Backtesting Results</h2>
      {data ? (
        <div>
          <p>Total Return: {data.totalReturn ? `${data.totalReturn.toFixed(2)}%` : 'N/A'}</p>
          <p>Sharpe Ratio: {data.sharpeRatio ? data.sharpeRatio.toFixed(2) : 'N/A'}</p>
          <p>Max Drawdown: {data.maxDrawdown ? `${data.maxDrawdown.toFixed(2)}%` : 'N/A'}</p>
          {/* Add more backtesting metrics as needed */}
        </div>
      ) : (
        <p>No backtesting data available</p>
      )}
    </div>
  );
};

export default BacktestingResults;
